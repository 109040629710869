
import { defineComponent, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";
import DonorFilters from "@/components/DonorFilters.vue";
import TableExplorer from "@/components/TableExplorer.vue";
import { StreamBarcodeReader } from "vue-barcode-reader";
import Donorfilters from "@/components/DonorFilters.vue";
import NewNotificationModal from "@/components/modals/NewNotification.vue";
import { Modal } from "bootstrap";
import RoadmapFilters from "@/components/RoadmapFilters.vue";

export default defineComponent({
  name: "roadmap",
  components: {
    RoadmapFilters,
    NewNotificationModal,
    TableExplorer,
  },
  props: {
    widgetClasses: String,
    transportFilter: Number,
  },
  data() {
    return {
      mapStyle: [
        {
          featureType: "transit",
          stylers: [
            {
              color: "#808080",
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],

      camReadMode: false,
      statusOptions: {
        active: "Activa",
        inactive: "Inactiva",
      },
      currentDate: {},
      exportingData: "off",
      transportList: [],
      userList: [],
      entities: null,
      page: 1,
      perPage: 50,
      pages: null,
      selectedDonor: null,
      interval: null,
      position: {
        lat: null,
        lng: null,
      },
      scanned: "",
      count: null,
      filters: {
        date: "",
      },
    };
  },
  methods: {
    requestTermination(donor) {
      (this.$refs.newsModal as any).clear();
      var modal = new Modal(document.getElementById("NewNotification-modal"));
      this.selectedDonor = donor;
      modal.show();
    },
    closeClaims(id) {
      ApiService.delete("donors/" + id + "/claims").then(() => {
        alert("Reclamo cerrado");
      });
    },
    retrieveData() {
      (this.$refs.data as any).retrieveData();
    },
    onLoad(a) {
      console.log("load");
    },
    onDecode(a) {
      if (this.interval) clearTimeout(this.interval as any);
      this.scanned = a;
      (this.interval as any) = setTimeout(() => {
        if (this.scanned == a) {
          (this.scanned as any) = null;
        }
      }, 3000);
    },
    getStatusClass(status) {
      switch (status) {
        case "active":
          return "svg-icon-success";
        case "inactive":
          return "svg-icon-danger";
        case "suspended":
          return "svg-icon-warning";
        default:
        case "irrecoverable":
          return "svg-icon-secondary";
      }
    },
    formatNumber(number) {
      return Number(number)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
    },
    onRetrieve(data) {
      this.currentDate = data.dates;

      if (this.filters.date == "") {
        this.filters.date = data.dates.current.display;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == "roadmap-explore") {
        this.filters.date =
          to.params.day + "/" + to.params.month + "/" + to.params.year;
        (this.$refs.data as any).retrieveData();
      }
    },
  },
  mounted() {
    navigator.geolocation.watchPosition(
      (position) => {
        (this.position.lat as any) = position.coords.latitude;
        (this.position.lng as any) = position.coords.longitude;
        //(this.$refs.data as any).retrieveData();
      },
      (error) => {
        console.log(error);
      }
    );

    navigator.geolocation.getCurrentPosition(() => {
      this.retrieveData();
    });

    watch(this.filters, () => {
      this.retrieveData();
    });
  },
});
