
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "NewNotificationModal",
  components: { },
  emits: ["notificationRegistered"],
  props: {
    donorId: Number,
    donor: Object,
  },
  computed: {
    canSendForm() {
      return this.type === "";
    },
  },
  methods: {
    catchErrors(error) {
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },

    storeNotification() {
      ApiService.post("news", {
        donor_id: this.donorId,
        type: this.type,
        details: this.observations,
      })
        .then(() => {
          let myModal = document.getElementById("NewNotification-modal");
          if (myModal) {
            myModal.click();
          }
          Swal.fire({
            text: "Notificación enviada",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Cerrar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.$emit("notificationRegistered");
        })
        .catch(this.catchErrors);
    },

    clear() {
      this.type = "";
      this.observations = "";
      this.errors = {};
    }
  },
  mounted() {
    ApiService.query("index/newstypes", {
      params: {
        mode: 'write',
      },
    }).then((res) => {
      this.typeList = res.data;
    });
  },
  setup() {
    const donorNotificationModal = ref<null | HTMLElement>(null);
    return { donorNotificationModal };
  },
  data: () => {
    return {
      loading: false,
      typeList: [],
      type: "",
      observations: "",
      errors: {},
    };
  },
});
