
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";

export default defineComponent({
  name: "roadmapFilters",
  props: {
    applied_filters: Object,
    allowed_filters: {
      type: Array,
      required: false,
      default: () => {
        return [
          "type",
          "status",
          "registration_date",
          "termination_date",
          "termination_reason",
          "id",
          "firstName",
          "lastName",
          "dni",
          "birthdate",
          "updated_at",
          "created_at",
          "address",
          "state",
          "locality",
          "transport",
          "visitor",
          "collector",
        ];
      },
    },
  },
  data() {
    return {
      terminationOptions: {},
      statusOptions: {
        active: "Activa",
        suspended: "Suspendida",
        inactive: "Inactiva",
      },
      filters: {
        type: "",
        registrationDate: [],
        terminationDate: [],
        created_at: [],
        terminationReason: [],
        status: "",
        id: "",
        address_number: "",
        address: "",
        transport: "",
        string: "",
        visitorId: null,
        collectorId: null,
        firstName: "",
        lastName: "",
        dni: "",
        birthdate: "",
        localityId: null,
        stateId: null,
        schedule: null,
        updated_at: [],
      },
      collectorList: [],
      transportList: [],
      stateList: [],
      visitorList: [],
      localityList: [],
    };
  },
  emits: ["changed"],
  methods: {
    refreshLocalities() {
      if (this.filters.stateId) {
        ApiService.query("localities", {
          params: { stateId: this.filters.stateId },
        }).then((response) => {
          this.localityList = response.data;
        });
      }
    },
    resetFilters() {
      this.filters.type = "";
      this.filters.registrationDate = [];
      this.filters.terminationDate = [];
      this.filters.created_at = [];
      this.filters.terminationReason = [];
      this.filters.status = "";
      this.filters.id = "";
      this.filters.address_number = "";
      this.filters.address = "";
      this.filters.transport = "";
      this.filters.string = "";
      this.filters.visitorId = null;
      this.filters.collectorId = null;
      this.filters.firstName = "";
      this.filters.lastName = "";
      this.filters.dni = "";
      this.filters.birthdate = "";
      this.filters.localityId = null;
      this.filters.stateId = null;
      this.filters.schedule = null;
      this.filters.updated_at = [];
      this.dataChanged();
    },
    dataChanged() {
      this.$emit("changed", this.filters);
    },
  },
  mounted() {
    watch(this.filters, () => {
      this.dataChanged();
    });

    (this.filters as any) = this.applied_filters;

    ApiService.query("index/users", {
      params: { permission: "place_visit", object: true },
    }).then((response) => {
      this.visitorList = response.data;
    });

    ApiService.query("index/users", {
      params: { permission: "collect", object: true },
    }).then((response) => {
      this.collectorList = response.data;
    });

    ApiService.get("index", "transports").then((response) => {
      this.transportList = response.data;
    });

    ApiService.get("index", "termination_reasons").then((response) => {
      this.terminationOptions = response.data;
    });

    ApiService.get("index", "states").then((response) => {
      this.stateList = response.data;
    });

    this.refreshLocalities();
  },
});
